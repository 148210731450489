<template>
  <div style="padding-left: 10px">
    <template v-for="group in itemData">
      <el-option-group v-if="group.children && group.children.length > 0"
          :key="group.id"
          :label="group.name"
      >
        <template v-if="group.children && group.children[0] && group.children[0].children">
          <unimate-group :item-data="group.children" />
        </template>
        <template v-else>
          <el-option
              v-for="item in group.children"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </template>
      </el-option-group>

      <el-option v-else
          :key="group.id"
          :label="group.name"
          :value="group.id">
      </el-option>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UnimateGroup',
  props: {
    itemData: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/ .el-select-dropdown__item {
    height: auto;
    min-height: 34px;
  }
</style>
