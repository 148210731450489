import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import main from "@/main"
import router from '@/router'
import { adminRole} from "@/api/login.js";
// import VueRouter from 'vue-router'
// import Vue from 'vue'
let arr = window.location.href.split("/");
// create an axios instance
const service = axios.create({
    baseURL: '/', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 100000, // request timeout
    retryDelay: 1000, // 请求间隙
    retry: 2, // 重试次数
    // withCredentials: true, // 是否携带cookie信息
    // headers: {
    //     'Content-Type': 'application/json; charset=*'
    // },
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // do something before request is sent

        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            // config.headers['X-Token'] = getToken()
        }
        //  config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
        return config
    },
    error => {
        // do something with request error
        ////console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {

        const res = response.data
        res.code *= 1;
        if (res.code == 400302) {
            Message({
                showClose: true,
                message: res.msg || 'Error',
                type: 'error',
                duration: 2 * 1000
            })
            // router.go(-1)
            // return res
            //请求拦截当没有权限的时候
            // console.log("您暂无访问权限！",res);

            if(res.msg=="您暂无访问权限！"){
                adminRole().then(res=>{
                  router.push(res.data)
                //   console.log("跳转接口",res);
                })
                return res
            }
            return res

        }



        //    判断是否已登陆过
        if (res.code === 400301) {
            // Message({
            //     message: res.msg || 'Error',
            //     type: 'error',
            //     duration: 2 * 1000
            // })
            let arr = location.href.split("/")
            ////console.log(arr);
            if (arr[arr.length - 1] != "userLogin" && arr[arr.length - 1] != "login") {
                Message({
                    showClose: true,
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 2 * 1000
                })
                if (arr[arr.length - 1].indexOf("answer") > -1) {
                    return location.href = '/index.html#/userLogin'
                } else {
                    return location.href = '/index.html#/login'
                }

            } else {
                return res
            }
        }
        if (res.code == 400201) {
            return res
        }

        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 400200) {
            Message({
                showClose: true,
                message: res.msg || 'Error',
                type: 'error',
                duration: 5 * 1000
            })



            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 500302) {
                // to re-login
                MessageBox.confirm(res.msg, '确定退出', {
                    confirmButtonText: 'Re-Login',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
            }
            // return Promise.reject(new Error(res.message || 'Error'))
            return res
        } else {
            return res

        }
    },
    error => {
        //console.log('err' + error) // for debug
        Message({
            showClose: true,
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service