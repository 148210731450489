<template>
<!--  <el-select v-model="modelValue" @change="handleChange">-->
<!--    <unimate-group :item-data="itemData" />-->
<!--  </el-select>-->

  <el-cascader v-model="myD" :options="getMyData" clearable @change="handleChange"
               :props="{ checkStrictly: true, label: 'name', value: 'id' }"></el-cascader>
</template>

<script>
import UnimateGroup from "./UnimateGroup";
export default {
  components: {UnimateGroup},
  data() {
    return {
      myD: null
    }
  },
  props: {
    itemData: {
      type: Array,
      default() {
        return []
      }
    },
    modelValue: {
      type: String | Number,
      default() {
        return ''
      }
    }
  },
  watch: {
    modelValue: {
      handler(v) {
        this.myD = v
      },
      immediate: true
    }
  },
  computed: {
    getMyData() {
      return this.clearChildren(this.itemData)
    }
  },
  methods: {
    handleChange(e) {
      console.log("e", e)
      this.$emit("input", e)
    },
    clearChildren(v) {
      return v.map(item => {
        if(item.children) {
          if(item.children.length === 0) {
            item.children = null
          } else {
            this.clearChildren(item.children)
          }
        }
        return item
      })
    }
  }
}
</script>
