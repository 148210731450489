// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import UnlimateSelect from "./components/unlimate/UnlimateSelect";
import * as echarts from 'echarts';
Vue.use(ElementUI);
// 引入自定义的全局公共css----------
import './assets/css/common.css'
import './assets/font-awesome-4.7.0/css/font-awesome.min.css'
// 引入echarts--------------
// import echarts from 'echarts'
Vue.prototype.$echarts = echarts
// ------------------------优化---------------------------
Vue.component("UnlimateSelect", UnlimateSelect)
import countTo from "vue-count-to"
Vue.component("countTo", countTo)

import Print from 'vue-print-nb'
Vue.use(Print);
//获取全局配置参数
import { getListConfig } from "@/api/comm.js";
var config = JSON.parse(localStorage.getItem("config"));
if (!config) {
    getListConfig().then(res => {
        if (res.code == 400200) {
            document.title = res.data.systematic_name;
            localStorage.setItem("config", JSON.stringify(res.data));
        }
    });
} else {
    document.title = config.systematic_name;
}












// ------------------------------------
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')
